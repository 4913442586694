<div class="btn-sidebar text-center" (click)="toggleMenu()" [ngClass]="{ 'btn-sidebar-minimized': isMinimized }">
  <i class="fas fa-chevron-left" style="font-size: 12px; "></i>
</div>
<div
  id="sidebar"
  class="bg-white row no-gutters"
  style="height: 100%; overflow-y: hidden;"
  [ngClass]="{ minimized: isMinimized }"
>
  <div class="icon-col vh-91 mt-2">
    <div class="row justify-content-center">
      <!-- <div class="logo-wrapper d-flex justify-content-center align-items-center"> -->
      <!-- <img class="logo" src="assets/icons/logo.png" /> -->
      <!-- </div> -->
    </div>

    <div class="d-flex" style="max-height: 47px;">
      <div class=" align-items-center ml-4 p-2">
        <img class="icon" (click)="goToHome()" src="assets/icons/home.svg" />
      </div>

      <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="goToHome()">
        <span>
          Home
        </span>
      </div>
    </div>

    <div class="d-flex">
      <div class=" align-items-center ml-4 p-2">
        <img class="icon" (click)="goToVagalumeStatus()" src="assets/icons/vagalume-status.svg" />
      </div>

      <div class="titles-wrapper align-items-center pointer ml-2 p-2" (click)="goToVagalumeStatus()">
        <span>
          Vagalume Status
        </span>
      </div>
    </div>

    <!-- <div class="d-flex">
      <div class=" align-items-center ml-4 p-2">
        <img class="icon" (click)="goToVagalumeStatus()" src="assets/icons/vagalume-status.svg" />
      </div>
  
      <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="goToVagalumeStatus()">
        <span>
          Vagalume Status
        </span>
      </div>
    </div> -->

    <!-- <div class="d-flex">
      <div class=" align-items-center ml-4 p-2">
        <img class="icon" (click)="goToUsuarios()" src="assets/icons/users.svg" />
      </div>
  
      <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="goToUsuarios()">
        <span>
          Usuários
        </span>
      </div>
    </div> -->

    <!-- <div class="d-flex">
      <div class=" align-items-center ml-4 p-2">
        <img class="icon" (click)="goToOrganization()" src="assets/icons/voucher.svg" />
      </div>
  
      <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="goToOrganization()">
        <span>
          Organizações
        </span>
      </div>
    </div>

    <div class="d-flex">
      <div class=" align-items-center ml-4 p-2">
        <img class="icon" (click)="goToConfiguration()" src="assets/icons/gear.svg" />
      </div>
  
      <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="goToConfiguration()">
        <span>
          Configurações em massa
        </span>
      </div>
    </div> -->

    <!-- <div class="row justify-content-center icons-wrapper">
      <div class="d-flex justify-content-center align-items-center">
        <ng-container *ngIf="collapsed ; then thenTemplate; else elseTemplate"></ng-container>
        <ng-template #thenTemplate>
          <p class="spacer">...</p>
        </ng-template>
        <ng-template style="overflow: visible;" #elseTemplate>
        </ng-template>
      </div>
    </div> -->

    <div class="accordion" id="accordionExample">
      <div *ngIf="vagamais == 'true'">
        <div class="titles-wrapper-nav pointer ml-2 mt-1">
          <div class="title-nav" (click)="openSidebar()">
            <img class="ml-4 icon" src="assets/icons/midias.svg" />
            <span
              class="ml-4 title"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              (click)="arrowRotate(0)"
              #arrowOne
              >Marketing
            </span>
            <span class="accicon"
              ><i [ngClass]="arrowIcons[0] ? 'uparrow' : 'downarrow'" class="fas fa-angle-down"></i
            ></span>
          </div>
        </div>

        <div #collapseOne id="collapseOne" class="collapse ml-4" data-parent="#accordionExample" style="cursor: auto;">
          <div class="ml-4">
            <a class="titles-nav" (click)="redirect(1)">Campanhas</a><br />
            <a class="titles-nav" (click)="redirect(2)">Enquetes</a><br />
            <!-- <a class="titles-nav" (click)="redirect(3)">SMS</a><br> -->
            <!-- <a class="titles-nav" (click)="redirect(4)">E-mail</a><br> -->
          </div>
        </div>
      </div>

      <!-- <div>
        <div class="titles-wrapper-nav pointer ml-2 mt-3" >
          <div class="title-nav" (click)="openSidebar()"> 
              <img class="ml-4 icon" src="assets/icons/perguntas.svg" />    
              <span class="ml-4 title" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" #arrowTwo (click)="arrowRotate(1)">WiFi </span>
              <span class="accicon"><i [ngClass]="arrowIcons[1] ? 'uparrow' : 'downarrow'" class="fas fa-angle-down"></i></span>
          </div>
        </div>
    
        <div #collapseTwo id="collapseTwo" class="collapse ml-4" data-parent="#accordionExample" style="cursor: auto;">
          <div class="ml-4"> -->
      <!-- <a class="titles-nav" (click)="redirect(5)">Vagalumes</a><br> -->
      <!-- <a class="titles-nav" (click)="redirect(6)">Voucher</a><br>
          </div>
        </div>
      </div> -->

      <div>
        <div class="titles-wrapper-nav pointer ml-2 mt-3">
          <div class="title-nav" (click)="openSidebar()">
            <img class="ml-4 icon" src="assets/icons/perguntas.svg" />
            <span
              class="ml-4 title"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              #arrowThree
              (click)="arrowRotate(2)"
              >Insight
            </span>
            <span class="accicon"
              ><i [ngClass]="arrowIcons[2] ? 'uparrow' : 'downarrow'" class="fas fa-angle-down"></i
            ></span>
          </div>
        </div>

        <div
          #collapseThree
          id="collapseThree"
          class="collapse ml-4"
          data-parent="#accordionExample"
          style="cursor: auto;"
        >
          <div class="ml-4">
            <ng-container *ngIf="validateMap != '' && validateMap == 'true'">
              <a class="titles-nav" (click)="redirect(7)">Mapas</a><br />
            </ng-container>
            <ng-container *ngIf="validateMenuService.validateDiagram">
              <a class="titles-nav" (click)="redirect(8)">Diagrama de Fluxo</a><br />
            </ng-container>
            <a class="titles-nav" (click)="redirect(9)">Público</a><br />
            <!-- <a class="titles-nav" (click)="redirect(10)">Acessos</a><br> -->
            <a class="titles-nav" (click)="redirect(11)">Recorrência</a><br />
            <a class="titles-nav" (click)="redirect(12)">Relatórios</a>
          </div>
        </div>
      </div>
      <div *ngIf="vagamais == 'true' && isAdm && isRoot">
        <div class="titles-wrapper-nav pointer ml-2 mt-1">
          <div class="title-nav" (click)="openSidebar()">
            <img class="ml-4 icon" src="assets/icons/midias.svg" />
            <span
              class="ml-4 title"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              (click)="arrowRotate(1)"
              #arrowTwo
              >Administração
            </span>
            <span class="accicon"
              ><i [ngClass]="arrowIcons[1] ? 'uparrow' : 'downarrow'" class="fas fa-angle-down"></i
            ></span>
          </div>
        </div>

        <div #collapseTwo id="collapseTwo" class="collapse ml-4" data-parent="#accordionExample" style="cursor: auto;">
          <div class="ml-4">
            <a class="titles-nav" (click)="redirect(14)">Organizações</a><br />
            <a class="titles-nav" (click)="redirect(15)">Relatorios Zabbix</a><br />
          </div>
        </div>
      </div>
      <div style="position: absolute; bottom: 20px">
        <div class="d-flex mb-2" *ngIf="isAdm && isRoot">
          <div class=" align-items-center ml-4 p-2">
            <img class="icon" (click)="goToUsuarios()" src="assets/icons/users.svg" />
          </div>

          <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="redirect(13)">
            <span>
              Usuários
            </span>
          </div>
        </div>

        <div class="d-flex">
          <div class=" align-items-center ml-4 p-2">
            <i class="fas fa-sign-out-alt" style="color: rgb(187, 187, 187);"></i>
          </div>

          <div class="titles-wrapper align-items-center pointer ml-2 p-2 mt-1" (click)="logout()">
            <span>
              Sair
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer" (click)="goToVagalumeStatus()">
        
      </div>
    </div> -->

      <!--

      <div class="row no-gutters titles-wrapper">
        <div class="d-flex align-items-center pointer" (click)="goToNotificationsPage()">
        <span>
          Notificação
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex justify-content-center align-items-center pointer" (click)="goToServiceStatus()">
        <span>
          Service Status
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer">
        <span>
          &nbsp;
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer">
        <span>
          Dashboard
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer">
        <span>
          Perguntas
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer">
        <span>
          Mídias
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer">
        <span>
          Voucher
        </span>
      </div>
    </div>
    <div class="row no-gutters titles-wrapper">
      <div class="d-flex align-items-center pointer">
        <span>
          Acesso Wi-fi
        </span>
      </div>
    </div>
  -->
    </div>
    <!-- <div class="col titles-col">

  </div> -->
  </div>

  <!-- <app-modal-change-clients></app-modal-change-clients> -->
</div>
