import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { Task, AppError } from '../data/api/task.model';
import { ConfigurationModel } from '../data/models/configuration.model';
import { ClientService } from './../services/client.service';
import { UsageLog } from '../data/models/swcusage-log.model';

import { moveSyntheticComments } from 'typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  configs: Task<ConfigurationModel> = Task.inProgress();
  public _configParams: { client_id: string; mac_vagalume: string } = {
    client_id:
      this.clientService.currentClient && this.clientService.currentClient.wisp_organization_id
        ? this.clientService.currentClient.wisp_organization_id
        : environment.wisp_default,
    mac_vagalume: ''
  }; // TODO: Replace real values with a call to the autorization API to then use the values

  constructor(
    private clientService: ClientService,
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  set configParams(params) {
    this._configParams = params;
  }

  async getUrl() {
    if (this.getMode_url() == 'elcoma') {
      return 'https://controller-staging.vagalumewifi.com.br/';
    } else if (this.getMode_url() == 'sedu') {
      return 'https://api-controller.wifi.sedu.es.gov.br/api';
    } else if (this.getMode_url() == 'semobi') {
      return 'https://api-controller.wifiterminais.semobi.es.gov.br/api';
    } else {
      return 'https://controller-staging.vagalumewifi.com.br/';
    }
  }

  getMode_url() {
    const url = location.href;
    if (url.indexOf('localhost') >= 0) {
      return 'elcoma';
    } else if (url.indexOf('semobi') >= 0) {
      return 'semobi';
    } else if (url.indexOf('sedu') >= 0) {
      return 'sedu';
    } else {
      return 'elcoma';
    }
  }

  async attConfigParamsMac(mac: string) {
    this._configParams = {
      client_id: this._configParams.client_id,
      mac_vagalume: mac
    };
  }

  get configParams(): { client_id: string; mac_vagalume: string } {
    //garantir que cliente esta sempre correto

    let client_id_aux =
      this.clientService.currentClient && this.clientService.currentClient.wisp_organization_id
        ? this.clientService.currentClient.wisp_organization_id
        : environment.wisp_default;
    if (client_id_aux != this._configParams.client_id) {
      this._configParams.client_id = client_id_aux;
    }

    return this._configParams;
  }

  async getConfig() {
    if (this.configs.isSuccessful) {
      this.configs = await this.getConfigInfo(this.configParams);

      return this.configs;
    } else {
      this.configs = await this.getConfigInfo(this.configParams);

      return this.configs;
    }
  }

  async getBulkConfig(mac) {
    let config = this.configParams;
    config.mac_vagalume = mac.toUpperCase();
    if (this.configs.isSuccessful) {
      this.configs = await this.getConfigInfo(config);
      return this.configs;
    } else {
      this.configs = await this.getConfigInfo(config);
      return this.configs;
    }
  }

  async postBulkConfig(bulkJob: any): Promise<Task<any>> {
    let api_request: string = `${environment.configApiUrl}/swarmcloud/bulkConfigJobs`;
    let payload: any = JSON.parse(JSON.stringify(bulkJob));
    delete payload.id;
    delete payload.jobStatus;
    delete payload.iteration;
    delete payload.endTime;
    delete payload.lastUpdate;
    delete payload.requestedBy;
    delete payload.orgList;
    let action = 'Configuração em massa';

    try {
      var response = await this.http.post(api_request, bulkJob).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async updateBulkConfig(bulkJob: any): Promise<Task<any>> {
    var bulkId = bulkJob.id;
    let api_request: string = `${environment.configApiUrl}/swarmcloud/bulkConfigJobs/${bulkId}`;
    let payload = { bulkId: bulkId };
    let action = 'Configuração em massa';

    try {
      delete bulkJob['id'];
      var response = await this.http.patch(api_request, bulkJob).toPromise();
      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async getBulkConfig_jobs() {
    try {
      const response = await this.http.get(`${environment.configApiUrl}/swarmcloud/bulkConfigJobs`).toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }

  async getMyUser() {
    return this.authenticationService.currentUserValue;
  }
  canEdit(): boolean {
    return this.authenticationService ? this.authenticationService.canEdit : false;
  }
  isRoot(): boolean {
    return this.authenticationService.isRoot;
  }
  async getUsersGroup() {
    try {
      const response = await this.http
        .get(`${environment.configApiUrl}/users_group/${this.authenticationService.currentUserValue.user.group.id}`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }
  async getCityCodes() {
    try {
      const response = await this.http.get(`${environment.configApiUrlCaptive}/codigo_cidades`).toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }
  async getOrganizationListOutdated() {
    const zabbix_group = JSON.parse(localStorage.getItem('currentClient')).groupZabbix.id;
    const wisp_organization_id = JSON.parse(localStorage.getItem('currentClient')).wisp_organization_id;
    let response: any;
    try {
      response = await this.http
        .get(`${environment.configApiUrl}/checkGroupOutdated/${wisp_organization_id}/${zabbix_group}`)
        .toPromise();
    } catch (e) {}
    return Task.success(response);
  }
  async getClientIDs() {
    let currentUser = this.authenticationService.currentUserValue;
    try {
      const response = await this.http
        .get(
          `${environment.configApiUrlCaptive}/clients` +
            `?filter={"fields":["name","id","controller_id"]}&` +
            `access_token=` +
            currentUser.token_captive
        )
        .toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }

  async getWispUUID(id: string) {
    try {
      const response = await this.http.get(`${environment.configApiUrl}/wisp_uuid/${id}`).toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }
  async getClientRogues(hostId: string) {
    try {
      if (localStorage.getItem('currentClient')) {
        var zabbixGroupId = JSON.parse(localStorage.getItem('currentClient')).groupZabbix.id;
      }
      const response = await this.http
        .get(`${environment.configApiUrl}/vagalume-aps/zabbix-rogue/${hostId}/${zabbixGroupId}`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }
  async sendEmailPassword(password: string, username: string) {
    try {
      const response = await this.http
        .get(`${environment.configApiUrlCaptive}/clients/send_email_password/` + password + '/' + username)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }
  async getGroups() {
    try {
      const response = await this.http.get(`${environment.configApiUrl}/groups`).toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }

  async getClients() {
    let controller_id = JSON.parse(localStorage.getItem('currentClient')).radio_prefix;

    try {
      const response = await this.http
        .get(`${environment.configApiUrl}/clients/getClientByClient/${controller_id}`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }

  async getStationsGroup() {
    try {
      if (localStorage.getItem('currentClient')) {
        var groupID = JSON.parse(localStorage.getItem('currentClient')).groupZabbix.id;
        var wisp_organization_id = JSON.parse(localStorage.getItem('currentClient')).wisp_organization_id;
        var controller_id = JSON.parse(localStorage.getItem('currentClient')).controller_id;
      }

      const response = await this.http
        .get(
          `${environment.configApiUrl}/vagalume-aps/zabbix-latest-groupstations/${groupID}/${wisp_organization_id}/${controller_id}`
        )
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }
  async getClientsGroup() {
    try {
      const response = await this.http
        .get(`${environment.configApiUrl}/clients_group/${this.authenticationService.currentUserValue.user.group.id}`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }
  async getClientsGroupES() {
    //alert("config")
    try {
      const response = await this.http
        .get(`${environment.configApiUrl}/groups_client/${this.authenticationService.currentUserValue.user.group.id}`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }
  async getNotRegistered(): Promise<Task<any>> {
    try {
      const response = await this.http
        .get(`${environment.configApiUrl}/swarmcloud/autoregister/notregistered`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }
  async getRegistered(): Promise<Task<any>> {
    let wisp_organization_id = JSON.parse(localStorage.getItem('currentClient')).wisp_organization_id;
    try {
      const response = await this.http
        .get(`${environment.configApiUrl}/swarmcloud/vagalumes_client/${wisp_organization_id}`)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }

  getClientList(): Observable<any> {
    return this.http.get<any>(`${environment.configApiUrl}/groups`);
  }

  async postRegister(mac_address: string): Promise<Task<any>> {
    let zabbixGroupId = this.clientService.currentClient.groupZabbix.id;
    let organizationId = this.clientService.currentClient.wisp_organization_id;
    let api_request = `${environment.configApiUrl}/swarmcloud/autoregister/register_vagalume/${mac_address}/${organizationId}/${zabbixGroupId}`;
    let payload = {};
    let action = 'Registrar AP';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async bulkSSID(config: object): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/bulk_ssid_status/${this.clientService.currentClient.wisp_organization_id}`;
    let payload = config;
    let action = 'Edição do SSID';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async bulkMacList() {
    try {
      const response = await this.http
        .get(
          `${environment.configApiUrl}/swarmcloud/wireless/ssid_mac_filter/${this.clientService.currentClient.wisp_organization_id}`
        )
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }

  async getListApsByOrganization() {
    try {
      const response: any = await this.http
        .get(
          `${environment.configApiUrl}/swarmcloud/wireless/list_aps_by_organization/${this.clientService.currentClient.wisp_organization_id}`
        )
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }
  async encryptionList() {
    try {
      const response = await this.http
        .get(
          `${environment.configApiUrl}/swarmcloud/wireless/ssid_security/${this.clientService.currentClient.wisp_organization_id}`
        )
        .toPromise();
      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }

  async bulkSSIDSecurity(config: object): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/bulk_ssid_security/${this.clientService.currentClient.wisp_organization_id}`;
    let payload = config;
    let action = 'Edição de configurações de segurança';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async bulkMacFilter(config: object): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/bulk_mac_filter/${this.clientService.currentClient.wisp_organization_id}`;
    let payload = config;
    let action = 'Edição de ACL';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async bulkMacDelFilter(config: { maclist: []; ssid: string }): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/bulk_mac_filter/${this.clientService.currentClient.wisp_organization_id}`;
    let payload = config;
    let action = 'Edição de ACL - remover mac';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        maclist: config.maclist,
        ssid: config.ssid
      }
    };
    try {
      var response = await this.http.delete(api_request, options).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async patchUser(user: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/users/${user.id}`;
    let payload = user;
    let action = 'Edição de Usuario';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async patchGroup(group: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/groups/${group.id}`;
    let payload = group;
    let action = 'Edição de Organização';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async deleteUser(id: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/users/${id}`;
    let payload = {};
    let action = 'Excluir Usuário';

    try {
      var response = await this.http.delete(api_request).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async deleteGroup(id: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/groups/${id}`;
    let payload = {};
    let action = 'Excluir Organização';

    try {
      var response = await this.http.delete(api_request).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async postUser(user: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/users/sign-up`;
    let payload = user;
    let action = 'Criar Usuário';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async postSchool(school: any): Promise<Task<any>> {
    let api_request = '';

    if (this.getMode_url() == 'sedu') {
      api_request = `${environment.configApiUrl}/swarmcloud/autoregister/school`;
    } else if (this.getMode_url() == 'semobi') {
      api_request = `${environment.configApiUrl}/swarmcloud/autoregister/terminal`;
    } else {
      api_request = `${environment.configApiUrl}/swarmcloud/autoregister/organization`;
    }
    let payload = school;
    let action = 'Criar Organização';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async editSchool(school: any): Promise<Task<any>> {
    let api_request = '';

    if (this.getMode_url() == 'sedu') {
      api_request = `${environment.configApiUrl}/swarmcloud/autoregister/school`;
    } else {
      api_request = `${environment.configApiUrl}/swarmcloud/autoregister/terminal`;
    }
    let payload = school;
    let action = 'Editar Organização';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async postVlan(vlan_id: number, mac_address: string): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/network/${this.configParams.client_id}/${mac_address}/vlan`;
    let payload = { vlan: vlan_id };
    let action = 'Criar VLAN';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async vagalumeReplicateConfig(config: object, device_id: string): Promise<Task<any>> {
    const wisp_organization_id = JSON.parse(localStorage.getItem('currentClient')).wisp_organization_id;

    try {
      const response = await this.http
        .post(`${environment.configApiUrl}/swarmcloud/bulk_config/ap_replicate/${device_id}`, config)
        .toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }

  async deleteVlan(vlan_id: number, mac_address: string): Promise<Task<any>> {
    let api_request =
      `${environment.configApiUrl}/swarmcloud/network/${this.configParams.client_id}/${mac_address}/vlan/` + vlan_id;
    let payload = {};
    let action = 'Excluir VLAN';

    try {
      var response = await this.http.delete(api_request).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async vagalumeReferenceConfig(device_id: string): Promise<Task<any>> {
    const wisp_organization_id = JSON.parse(localStorage.getItem('currentClient')).wisp_organization_id;
    let api_request = `${environment.configApiUrl}/swarmcloud/vagalume_reference_config/${wisp_organization_id}`;
    const obj = { device_id: device_id };
    try {
      var response = await this.http.post(api_request, obj).toPromise();

      return Task.success(response);
    } catch (e) {
      throw new Error(e);
    }
  }
  async postUnRegister(mac_address: string): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/autoregister/register_vagalume/${mac_address}/${environment.wisp_default}/${environment.zabbix_default}`;
    let payload = {};
    let action = 'Desassociação de AP';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async editHostname(mac_address: string, new_host: string): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/vagalume/${this.configParams.client_id}/${mac_address}`;
    let payload = {
      prefix: this.clientService.currentClient.controller_id,
      name: new_host
    };
    let action = 'Editar Hostname';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async getConfigInfo(params: { client_id: string; mac_vagalume: string }): Promise<Task<ConfigurationModel>> {
    try {
      const response = await this.http
        .get(
          `${environment.configApiUrl}/swarmcloud/wireless/${
            this.configParams.client_id
          }/${params.mac_vagalume.toUpperCase()}/config_info`
        )
        .toPromise();

      return Task.success({
        interfaces: response['interfaces'],
        radios: response['radios'],
        network_interfaces: response['network_interfaces'],
        dhcp_addresses: response['dhcp_addresses']
      });
    } catch (e) {
      throw new Error(e);
    }
  }
  async updateInterfaceConfig(payload: any, iface: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/interface/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}/${iface}`;
    //let payload = payload
    let action = 'Atualizar configurações interface';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      alert(e);
      throw new Error(e);
    }
  }
  async createNetworkConfig(payload: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/network/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}/interface`;
    //let payload = {}
    let action = 'Criar nova interface de rede';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async createInterfaceConfig(payload: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/interface/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}`;
    //let payload = {}
    let action = 'Criar nova interface Wireless';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async bulkVagalumeConfig(payload: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/bulk_config/ap_replicate/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}`;
    //let payload = {}
    let action = 'Replicar configuração de um Vagalume';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async updateFirmware(payload: any): Promise<Task<any>> {
    let api_request = `${
      environment.configApiUrl
    }/updateVagalumeFirmware/${this.configParams.mac_vagalume.toUpperCase()}`;
    //let payload = {}
    let action = 'Atualização de firmware';

    try {
      var response = await this.http.post(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async updateNetworkConfig(payload: any, iface: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/network/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}/interface/${iface}`;
    //let payload = {}
    let action = 'Edição de interface de rede: ' + iface;

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async updateInterfacesSSID(currentSSID: string, newSSID: string): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/bulkssid/${this.configParams.client_id}/${currentSSID}/${newSSID}`;
    let payload = { currentSSID: currentSSID, newSSID: newSSID };
    let action = 'Edição SSID da organização';

    try {
      var response = await this.http.patch(api_request, {}).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }
  async deleteInterface(interfaceName: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}/interface/${interfaceName}`;
    let payload = { interfaceName: interfaceName };
    let action = 'Excluir Interface Wireless ' + interfaceName;

    try {
      var response = await this.http.delete(api_request, {}).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async deleteNetwork(interfaceName: any): Promise<Task<any>> {
    let api_request = `${environment.configApiUrl}/swarmcloud/network/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}/interface/${interfaceName}`;
    let payload = { interfaceName: interfaceName };
    let action = 'Excluir Interface ' + interfaceName;

    try {
      var response = await this.http.delete(api_request, {}).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async updateRadioConfig(payload: any): Promise<Task<any>> {
    delete payload.channel_config;
    let api_request = `${environment.configApiUrl}/swarmcloud/wireless/radio/${
      this.configParams.client_id
    }/${this.configParams.mac_vagalume.toUpperCase()}`;
    //let payload = {currentSSID:currentSSID, newSSID:newSSID}
    let action = 'Edição Configuração Radio';

    try {
      var response = await this.http.patch(api_request, payload).toPromise();

      let status: object = { status: 'success' };
      this.logInteraction(action, payload, api_request, status);

      return Task.success(response);
    } catch (e) {
      let status: object = { status: 'fail', detail: e };
      this.logInteraction(action, payload, api_request, status);
      throw new Error(e);
    }
  }

  async getRadiusReport(dateInicial, dataFinal) {
    try {
      const response = await this.http
        .get(
          `${environment.configApiUrl}/relatorio/${
            this.clientService.currentClient.controller_id
          }/${dateInicial.getTime()}/${dataFinal.getTime()}`
        )
        .toPromise();

      return Task.success({
        data: response
      });
    } catch (e) {
      return Task.error(e);
    }
  }
  async getScan(mac) {
    try {
      const response = await this.http.get(`${environment.configApiUrl}/zabbix-scan?mac=${mac}`).toPromise();

      return Task.success(response);
    } catch (e) {
      return Task.error(e);
    }
  }

  async logInteraction(action: string, payload: object, api_request: string, status: object) {
    let myUser: any = await this.getMyUser();

    let myOrg: any = this.clientService.currentClient;
    let usage_log: UsageLog = {
      datetime: new Date(),
      user: myUser.username + ' @' + myOrg.controller_id,
      action: action,
      org_id: myOrg.id,
      payload: JSON.stringify(payload),
      endpoint: api_request,
      status: JSON.stringify(status)
    };

    await this.http.post(`${environment.configApiUrl}/swarmcloud/swcusage-logs/`, usage_log).toPromise();
  }
}
