import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ValidateMenuService } from 'src/app/services/validate-menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() collapsed: boolean;

  arrowIcons: boolean[] = [false, false, false];

  isMinimized: boolean = false;

  validateMap = localStorage.getItem('validateMap');
  vagamais = localStorage.getItem('vagamais');
  isAdm = JSON.parse(localStorage.getItem('currentUser')).user.is_adm;
  isRoot: boolean = false;

  @ViewChild('collapseOne') collapseOne: ElementRef;
  @ViewChild('collapseTwo') collapseTwo: ElementRef;
  @ViewChild('collapseThree') collapseThree: ElementRef;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public validateMenuService: ValidateMenuService
  ) {}

  flag: boolean = false;
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('currentClient'));
    if ('group' in currentUser) {
      if (currentUser.group.text == 'ROOT') {
        this.isRoot = true;
      } else {
        this.isRoot = false;
      }
    }
  }

  goToVagalumeStatus() {
    this.router.navigate(['/vagalume-status']);
  }

  goToNotificationsPage() {
    this.router.navigate(['notifications-page']);
  }

  goToServiceStatus() {
    this.router.navigate(['/service-status']);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  goToUsuarios() {
    this.router.navigate(['/user']);
  }

  goToOrganization() {
    this.router.navigate(['/organization']);
  }

  goToConfiguration() {
    this.router.navigate(['/bulkconfig']);
  }

  goToClientes() {
    this.router.navigate(['/clientes']);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  redirect(tipo) {
    if (tipo == 1) {
      this.router.navigate(['/videos']);
    } else if (tipo == 2) {
      this.router.navigate(['/perguntas']);
    } else if (tipo == 3) {
      this.router.navigate(['/sms']);
    } else if (tipo == 4) {
      this.router.navigate(['/emails']);
    } else if (tipo == 5) {
      this.router.navigate(['/radios']);
    } else if (tipo == 6) {
      this.router.navigate(['/voucher']);
    } else if (tipo == 7) {
      this.router.navigate(['/maps']);
    } else if (tipo == 8) {
      this.router.navigate(['/diagrama-fluxo']);
    } else if (tipo == 9) {
      this.router.navigate(['/publico']);
    } else if (tipo == 10) {
      this.router.navigate(['/acessos']);
    } else if (tipo == 11) {
      this.router.navigate(['/recurrence']);
    } else if (tipo == 12) {
      this.router.navigate(['/relatorios']);
    } else if (tipo == 13) {
      this.router.navigate(['/user']);
    } else if (tipo == 14) {
      this.router.navigate(['/organization']);
    } else if (tipo == 15) {
      this.router.navigate(['/relatorios-zabbix']);
    }
  }

  arrowRotate(index) {
    if (index == 0) {
      this.arrowIcons[0] = !this.arrowIcons[0];
      this.arrowIcons[1] = false;
      this.arrowIcons[2] = false;
    } else if (index == 1) {
      this.arrowIcons[1] = !this.arrowIcons[1];
      this.arrowIcons[0] = false;
      this.arrowIcons[2] = false;
    } else if (index == 2) {
      this.arrowIcons[2] = !this.arrowIcons[2];
      this.arrowIcons[0] = false;
      this.arrowIcons[1] = false;
    }
  }

  toggleMenu() {
    this.isMinimized = !this.isMinimized;

    this.collapseOne.nativeElement.classList.remove('show');
    // this.collapseTwo.nativeElement.classList.remove('show');
    this.collapseThree.nativeElement.classList.remove('show');

    this.arrowIcons[0] = false;
    this.arrowIcons[1] = false;
    this.arrowIcons[2] = false;
  }

  openSidebar() {
    this.isMinimized ? (this.isMinimized = false) : (this.isMinimized = false);
  }
}
